import { lazy } from 'react';

const RatingReviews = lazy(() => import('./list'))

const RatingReviewView = (props) => {

    return <RatingReviews />

}

export default RatingReviewView;