import { Result, Button, Skeleton } from 'antd'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import menuData from '../../routes'
import Media from 'react-media';
import WebpImage from '../../assets/mobilePlaceholder.webp'
const AuthLayout = lazy(() => import('../../layouts/AuthLayout'))
const BasicLayout = lazy(() => import('../../layouts/basicLayout'))
const Login = lazy(() => import('../authentication/login'))
const NotFound = lazy(() => import('../../components/Exception/404'))

const Exp = () => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <NotFound />
</div>)
export default (props) => {
    return (
        <Media queries={{ small: { maxWidth: 950 } }}>
            {matches =>
                matches.small ?
                    <div className='flex' style={{ height: '100vh', justifyContent: 'center' }}>
                        <Result
                            icon={
                                <img className='mobilePlaceholder' src={WebpImage} type="image/webp" />
                            }
                            title="You're all set"
                            subTitle="To get started in mobile download our app for Android or IOS"
                            extra={<Button className='rounded' type="primary" href='https://play.google.com/store/apps/details?id=com.collaberus.quickse' target='__blank'>Download QuickSe App</Button>}
                        />
                    </div> :



                    <Suspense fallback={<div style={{ padding: '2rem' }}>
                        <Skeleton active />
                    </div>}>
                        <Switch>
                            {menuData.map((item, key) => {

                                if (!item.children) {
                                    return (<Route exact path={item.path} key={item.path} render={(route) => {
                                        return <BasicLayout path={item.path} menuData={menuData} item={item} menuData={menuData}>
                                            {!!item.component ?
                                                <Suspense fallback={<div>
                                                    <Skeleton active />
                                                </div>}>
                                                    {item.component}
                                                </Suspense> : <></>}
                                        </BasicLayout>
                                    }} />)
                                }
                            })}

                            {menuData.map((item, key) => {
                                if (item.children) {
                                    return item.children.map((child, k) => {
                                        return (<Route exact path={child.path} key={child.path} render={(route) => {
                                            return <BasicLayout path={child.path} menuData={menuData} item={item} menuData={menuData} >
                                                {!!child.component ?
                                                    <Suspense fallback={<div>
                                                        <Skeleton active />
                                                    </div>}>
                                                        {child.component}
                                                    </Suspense> : <></>}
                                            </BasicLayout>
                                        }} />)
                                    })
                                }
                            })}

                            <Route exact path={'/'} key={'login'} render={(route) => <AuthLayout>
                                <Login />
                            </AuthLayout>} />
                            <Route render={(route) => <Exp />} />

                        </Switch>
                    </Suspense>
            }
        </Media>
    )
};