import { notification } from "antd"
import { getPushPathWrapper } from "../routes"
import { RupeeSymbol } from "../settings"

const initialState = {
  notificationCount: 0,
  notifications: []
}
function goToOrder(id, dispatch) {
  dispatch(getPushPathWrapper('orderDetails', { id }))
}
export default (state = initialState, action) => {
  switch (action.type) {

    case 'SET_NOTIFICATION':
      let newNotification = [...state.notifications, action.payload]
      return {
        ...state,
        notifications: newNotification,
        notificationCount: newNotification.length
      }
    case 'SET_NOTIFICATIONS':
      let newNotifications = action.payload
      return {
        ...state,
        notifications: newNotifications,
        notificationCount: newNotifications.length
      }

    case 'CLEAR_NOTIFICATION':
      return {
        ...state,
        notifications: [],
        notificationCount: 0
      }

    default:
      return state
  }
}
