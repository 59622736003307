import moment from "moment"
import request from "../request"

const initialState = {
  currentUser: null,
  mobileNo: null,
  token: null,
  headTitle: null,
  headBack: null,
  buttonLoading: false,
  showDownlaodButton: true,
  showReportButton: true,
  otpTimer: moment().subtract(1, 'minute').valueOf(),
  
}

export default (state = initialState, action) => {
  switch (action.type) {

    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.payload
      }

    case 'SET_MOBILE':
      return {
        ...state,
        mobileNo: action.payload
      }

    case 'SET_HEAD_TITLE':
      return {
        ...state,
        headTitle: action.payload
      }

    case 'SET_HEAD_BACK':
      return {
        ...state,
        headBack: action.payload
      }

    case 'LOGOUT':
      request.logout()
      return {
        ...state,
        currentUser: null,
        token: null
      }

    case 'SHOW_BTN_LOADING':
      return {
        ...state,
        buttonLoading: true
      }

    case 'HIDE_BTN_LOADING':
      return {
        ...state,
        buttonLoading: false
      }


    case 'SET_AUTH_TOKEN':
      window.localStorage.setItem('token', action.payload)
      return {
        ...state,
        token: action.payload
      }

    case 'SET_BUTTON_SETTING':
      return {
        ...state,
        showDownlaodButton: action.payload
      }

    case 'SET_REPORT_SETTING':
      return {
        ...state,
        showReportButton: action.payload
      }

    case 'SET_OTP_TIMER':
      return {
        ...state,
        otpTimer: action.payload
      }
    
    default:
      return state
  }
}
