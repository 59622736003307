import React, { lazy } from 'react'
import _ from 'lodash'
import { Path } from 'path-parser'
import { ReactComponent as OrderIcon } from './assets/orderIcon.svg'
import { ReactComponent as ProductIcon } from './assets/productIcon.svg'
import { ReactComponent as HomeIcon } from './assets/homeIcon.svg'
import { ReactComponent as DiscountIcon } from './assets/discount.svg'

import { push } from 'connected-react-router'
import { FormOutlined, MoneyCollectOutlined, UserOutlined, DollarOutlined, TeamOutlined, NotificationOutlined, StarOutlined, PaperClipOutlined, PicLeftOutlined } from '@ant-design/icons'
import RatingReviewView from './containers/ratingreviews'
import AdCampaign from './containers/adCampaignRequest'

const Home = lazy(() => import('./containers/home'))
const Product = lazy(() => import('./containers/products'))
const Order = lazy(() => import('./containers/order'))
const CustomersView = lazy(() => import('./containers/customers'))
const ShopkeeperList = lazy(() => import('./containers/shopkeepers'))
const ContactUs = lazy(() => import('./containers/contactUs'))
const StoreReports = lazy(() => import('./containers/storeReports'))
const Transactions = lazy(() => import('./containers/transactions'))
const Payouts = lazy(() => import('./containers/payouts'))
const Partners = lazy(() => import('./containers/partners'))
const NotificationTemplates = lazy(() => import('./containers/notificationTemplates'))
const DelieveryPerson = lazy(() => import('./containers/delieveryPerson'))
const PinCode = lazy(() => import('./containers/pinCode'))
const Promocode = lazy(() => import('./containers/promocode'))

const Alerts = lazy(() => import('./containers/alerts'))

const menu = [
  {
    'path': '/home',
    'name': 'Home',
    'icon': <HomeIcon />,
    'key': 'home',
    'component': <Home />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/shopkeepers',
    'name': 'Shopkeepers',
    'icon': <UserOutlined />,
    'key': 'shopkeeper',
    'component': <ShopkeeperList />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/customers',
    'name': 'Customers',
    'icon': <UserOutlined />,
    'key': 'customer',
    'component': <CustomersView />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/products',
    'name': 'Products',
    'icon': <ProductIcon />,
    'key': 'product',
    'component': <Product />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/orders',
    'name': 'Orders',
    'icon': <OrderIcon />,
    'key': 'order',
    'component': <Order />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/transactions',
    'name': 'Transactions',
    'icon': <MoneyCollectOutlined />,
    'key': 'transaction',
    'component': <Transactions />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/payouts',
    'name': 'Payouts',
    'icon': <DollarOutlined />,
    'key': 'payout',
    'component': <Payouts />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/queries',
    'name': 'Queries',
    'icon': <FormOutlined />,
    'key': 'queries',
    'component': <ContactUs />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/repoted-stores',
    'name': 'Reports',
    'icon': <FormOutlined />,
    'key': 'repotedStores',
    'component': <StoreReports />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/alerts',
    'name': 'Alerts',
    'icon': <TeamOutlined />,
    'key': 'alert',
    'component': <Alerts />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/partners',
    'name': 'Partner',
    'icon': <TeamOutlined />,
    'key': 'partners',
    'component': <Partners />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/notification-templates',
    'name': 'Templates',
    'icon': <NotificationOutlined />,
    'key': 'notificationTemplates',
    'component': <NotificationTemplates />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/delievery-person',
    'name': 'Delivery Person',
    'icon': <TeamOutlined />,
    'key': 'deliveryPerson',
    'component': <DelieveryPerson />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/pincode',
    'name': 'Pin Code',
    'icon': <TeamOutlined />,
    'key': 'pinCode',
    'component': <PinCode />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/promocode',
    'name': 'Promo Code',
    'icon': <DiscountIcon />,
    'key': 'promoCodes',
    'component': <Promocode />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/ratingreviews',
    'name': 'Rating & Reviews',
    'icon': <StarOutlined />,
    'key': 'ratingreviews',
    'component': <RatingReviewView />,
    'authority': [
      'user'
    ]
  },
  {
    'path': '/adcampaignrequest',
    'name': 'Ad Campaign',
    'icon': <PicLeftOutlined />,
    'key': 'adcampaignrequest',
    'component': <AdCampaign />,
    'authority': [
      'user'
    ]
  },
]

// utils for path

export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params = {}) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)
    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)
  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}


export default menu;