import { configConsumerProps } from "antd/lib/config-provider";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router";
import { apiUrl, DateFormat, customerPortalUrl } from "./settings";
import { store } from "./store";
export const fileUploadUrl = `${apiUrl}api/file-upload/image/`;
export const productImageUrl = `${apiUrl}/media/`;
export const notificationTemplate = `http://122.186.88.170:8009/api/file-upload/sample-file/get/?filename=templateSample`;
export const pinCodesSample = `${apiUrl}/api/file-upload/sample-file/get/?filename=pincodeSample`;
export const deliveryPersonSample = `${apiUrl}api/file-upload/sample-file/get/?filename=deliverySample`;
export const partnerSample = `${apiUrl}/api/file-upload/sample-file/get/?filename=partnerSample`;

export const storeUrl = (storeId, productCategoryId, productId) => {
    if (!productCategoryId && !productId && storeId)

        return `${customerPortalUrl}/${storeId}`
    else if (productCategoryId && !productId && storeId)
        return `${customerPortalUrl}/${storeId}/${btoa(productCategoryId)}`
    else
        return `${customerPortalUrl}/${storeId}/${btoa((productCategoryId + '/' + productId).toString())}`
}
export const storeMessage = (shop, url) => encodeURIComponent(
    `Hello !\nWe are now online too and taking orders with delivery to your doorsteps.\n\nCheckout our Online store link: ${url || storeUrl(shop?.storeId)}\n\nFeel free to call us on ${shop?.mobile} if you need any help with ordering online.\nThank you !\n${shop?.storeName}`
)
export const couponMessage = (shop, coupon) => encodeURIComponent(
    `Coupon Code: *${coupon.couponCode}*\n${coupon.discountAmount}${coupon.discountType === 'PERCENTAGE' ? '%' : ''} off on first ${coupon.usesPerCustomer} orders per user\n${storeUrl(shop?.storeId)}\n\nFeel free to call us on ${shop?.mobile} if you need any help with ordering online.\nThank you !\n${shop?.storeName}`
)



let authAxios = axios.create({
    baseURL: apiUrl,

})

let authAxios1 = axios.create({
    baseURL: apiUrl,

})

const range = localStorage.getItem('dateRange')

console.log(range, 'range1')

authAxios1.interceptors.request.use(function (request) {
    // Do something before request is sent
    request.responseType = 'blob'
    return request;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

authAxios1.interceptors.request.use(function (config) {
    // Do something before request is sent
    // config.data ='{"dateRange":"2022-06-01,2022-06-11"}'
    if (localStorage.getItem('dateRange') != 'undefined') {
        config.params = {
            count: undefined,
            dateRange: localStorage.getItem('dateRange')
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});




export const getToken = () => {
    return {
        headers: {
            Authorization: 'Token ' + localStorage.getItem('token')
        },
        // headers: { Authorization: 'Token ' + 'c38602aa24f9850ac02598cb109ac909b8870fa7' }
    }
}



class Request {

    error = err => {
        try {
            if (err.response.status === 401 || err.response.status === 403) {
                // store.dispatch({ type: 'LOGOUT' })
                // localStorage.clear()
                // window.location.reload()
            }
        } catch (e) {
        }
    }

    // ------------------------------------------- API Start from here --------------------------------------------- //

    getProfile() {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/user/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    signup(data, recaptchaToken) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/create/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    logout(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/logout/', {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/login/', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    notificationSampleDownload() {
        return new Promise((next, error) => {
            authAxios1
                .get('/api/file-upload/sample-file/get/?filename=templateSample', {}, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Notification_Template_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    pincodeSampleDownload() {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/file-upload/sample-file/get/?filename=pincodeSample', {}, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Pincode_Sample_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    deliverySampleDownload() {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/file-upload/sample-file/get/?filename=deliverySample', {}, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Delivery_Person_Sample_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    partnerSampleDownload() {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/file-upload/sample-file/get/?filename=partnerSample', {}, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Partner_Sample_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    changePassowrd(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/change/password/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // user api

    // dashboard api

    // products api

    exportProduct(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/product-variant/export/', data, getToken())
                .then(d => {
                    console.log('data', data)
                    console.log(d, 'produ')
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = 'Product Report';  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    console.log(d.data, 'data1')
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getProductById(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/product/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getProducts(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/product/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getProductVariants(data = {}) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/product-variant/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // orders api

    getOrders(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/order/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateOrder(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/status-update/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateOrderDetails(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/shipping-details/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    orderRefund(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/refund_initiate_post/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    // console.log(err.response.status, "ASDasd")
                    this.error(err)
                })
        })
    }
    getRefundDetails(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/refund_initiate_get/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getOrderUpdateTimings(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order/status/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // business categories api

    // bulk upload

    bulkUpload(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/order/shipping-details/bulk/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    exportTemplate(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/notification-template/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Notification_Template_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    exportDeliveryPerson(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('api/compliance/deliveryboy/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Delivery_Person_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    exportPinCode(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('api/compliance/pincode/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Delivery_Pin_Codes_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    exportPartners(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/compliance/partners/export/', data, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);    // Create downloaded link
                    const now = moment()
                    aEle.href = href;
                    aEle.download = `Partners_List_${now.format('DD_MM_YYYY')}).xls`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    bulkUploadTemplate(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/compliance/notification-template/import/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    bulkUploadPinCode(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/compliance/pincode/import/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    bulkUploadDeliveryPerson(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/compliance/deliveryboy/import/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    bulkUploadPartner(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/compliance/partner-account/import/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/order/report/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: `${data?.exportFormat === 'xls' ? 'application/vnd.ms-excel' : 'application/pdf'}` })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `OrderReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')


                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodShopkeeperReport(data) {
        console.log('export shopkeeper', data)
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/shopkeepers/export/', data, getToken())
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `ShopkeepersReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    console.log(d, 'shopkeeper1')
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodCustomerReport(data) {
        console.log(data, 'cust1')
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/customers/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `CustomersReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodTransactionsReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/transactions/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `TransactionsReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodPayoutsReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/payouts/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `PayoutReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodQueriesReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/queries/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `QueriesReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodReportsReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('api/compliance/reports/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `ReportsReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodPartnersReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/partners/export/', data, getToken())
                .then(d => {
                    console.log(d, 'partner')

                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `PartnersReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    console.log(d, 'partner1')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodAlertsReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/alerts/export/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'application/vnd.ms-excel' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `AlertsReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    downlaodDunzoReport(data) {
        return new Promise((next, error) => {
            authAxios1
                .post('/api/compliance/dunzo/order-report/', data, getToken(), { responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: `${data?.exportFormat === 'xls' ? 'application/vnd.ms-excel' : 'application/pdf'}` })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.download = `Dunzo OrderReport (${data?.dateRange ? data?.dateRange?.replace(',', ' to ') : moment().format(DateFormat)}))`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)
                    localStorage.removeItem('dateRange')

                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getContactUsForms(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/contact-us/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // Comment

    UpdateComment(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/update/contact-us/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    //customers api

    getCustomers(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/customer/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getShopkeepers(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/shopkeeper/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getRatingReviews(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/rating/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getAdCampaignRequests(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/complaince/shop-campaign-request/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getAdCampaignRequestsById(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/customer/campaign-request/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addAdCampaignCreation(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/complaince/shop-campaign-request/add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateOwnerAdCampaignRequests(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/complaince/campaign-request-update/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    adCampaignLiveStatus(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/campaign-request/livestatus/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    getAdCampaignCreationDetails(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/complaince/shop-campaign-request/get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addAdCampaignTransaction(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/ads-transaction/post/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getAdCampaignTransaction(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/ads-transaction/get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    shopkeeperRestriction(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/shopperbyid/restriction/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    setRatingReviewStatus(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/update-rating-review/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    shopkeeperLiveStatus(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/shopperbyid/restriction/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }


    shopkeeperUploadProfile(data, id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/shopkeeper-profile/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // transactions

    getTransactions(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/transaction/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getPayouts(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/payout-transaction/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    //report store

    getStoreReport(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/report-store/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    // coupons api

    addCoupon(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/compliance-coupon/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }

    getCoupons(data) {
        return new Promise((next, error) => {
            authAxios
                .post('api/get-compliance-coupon/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)

                })
        })
    }

    updateCoupon(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`api/compliance-coupon/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }
    deleteCoupon(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`api/compliance-coupon/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    setTimeout(() => this.error(err), 0)
                })
        })
    }



    // dashboard apis

    getAnalyticData(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/analytics/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getAlerts(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/APIErrors/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    addPartner(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/partner_account_add/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updatePartner(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/partner_account/${id}/`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getPartners(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/PartnerAccountList/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addTemplate(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/notify/create/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateTemplate(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/notify/update/`, { ...data, id }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteTemplate(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/notify/delete/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getTemplates(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/notify/get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addDelieveryPerson(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/deliveryBoy/create/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updateDelieveryPerson(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/deliveryBoy/update/`, { ...data, id }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteDelieveryPerson(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/deliveryBoy/delete/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getDelieveryPerson(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/deliveryBoy/get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getDelieveryPersonOrders(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/deliveryboy-shipping-get/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    addPincode(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/pincodeadd/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    updatePincode(id, data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/compliance/pincode/${id}/`, { ...data }, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    deletePincode(id) {
        return new Promise((next, error) => {
            authAxios
                .delete(`/api/compliance/pincode/${id}/`, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    getPincode(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/compliance/pincodelist/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }

    sendNotification(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/api/send/notify/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    resendOtp(id) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/order-resend-otp/${id}/`, {}, getToken())
                .then(d => {
                    next(d.data)
                    // console.log(d,'ddddd')
                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    getDeliveryBoyDetails(data) {
        return new Promise((next, error) => {
          authAxios
            .post("/api/compliance/delivery-boy-details/get/", data, getToken())
            .then((d) => {
              next(d.data);
            })
            .catch((err) => {
              next({ error: true, err });
              this.error(err);
            });
        });
      }
      getdeliveryDetailsWithCollapsed(data) {
        return new Promise((next, error) => {
          authAxios
            .post(`/api/compliance/deliveryboyDetail/collapasedview/`,data, getToken())
            .then((d) => {
              next(d.data);
            })
            .catch((err) => {
              next({ error: true, err });
              this.error(err);
            });
        });
      }
      downloadDeliveryReport(data) {
        return new Promise((next, error) => {
          authAxios1
            .post("/api/compliance/Delivery/order-report/", data, getToken(), {
              responseType: "blob",
            })
            .then((d) => {
              const blob = new Blob([d.data], {
                type: `${
                  data?.exportFormat === "xls"
                    ? "application/vnd.ms-excel"
                    : "application/pdf"
                }`,
              });
              const aEle = document.createElement("a"); // Create a label
              const href = window.URL.createObjectURL(blob); // Create downloaded link
              aEle.href = href;
              aEle.download = `OrderDeliveredReport (${
                data?.dateRange
                  ? data?.dateRange?.replace(",", " to ")
                  : moment().format(DateFormat)
              }))`; // File name after download
              document.body.appendChild(aEle);
              aEle.click(); // Click to download
              document.body.removeChild(aEle); // Download complete remove element
              window.URL.revokeObjectURL(href);
              next(d.data);
              localStorage.removeItem("dateRange");
            })
            .catch((err) => {
              next({ error: true, err });
              this.error(err);
            });
        });
      }
    
    downloadAdsImage(data) {
        return new Promise((next, error) => {
            authAxios
                .post(`/api/download-ads-banners/`,data,{ responseType: 'blob' })
                .then(d => {
                    const blob = new Blob(
                        [d.data], { type: 'image/jpeg' })
                    const aEle = document.createElement('a');     // Create a label
                    const href = window.URL.createObjectURL(blob);       // Create downloaded link
                    aEle.href = href;
                    aEle.target = '_blank';
                    aEle.download = `my-card.jpeg`;  // File name after download
                    document.body.appendChild(aEle);
                    aEle.click();     // Click to download
                    document.body.removeChild(aEle); // Download complete remove element
                    window.URL.revokeObjectURL(href)
                    next(d.data)

                })
                .catch(err => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
}

export default new Request();
