export const apiUrl = process.env.REACT_APP_API_URL
export const socketUrl = process.env.REACT_APP_SOCKET_URL
// export const apiUrl = 'http://122.186.88.170:8000'
// export const socketUrl = 'ws://122.186.88.170:8000/ws/compliance'
// export const socketUrl = 'wss://api.quickse.in/ws/compliance'
// export const apiUrl = 'https://api.quickse.in'

export const fbAppId = '687988341580190'
export const FilterDateFormat = 'YYYY-MM-DD'
export const DateFormat = 'DD-MM-YYYY HH:mm A'
export const dateFormat = 'do MMMM YYYY'
export const MaxImageUpload = 7
export const MaxAdCampaignUpload = 1
export const RupeeSymbol = '₹ '
export const tabMaxWidth = 1100
export const productName = 'QuickSe'
// export const customerPortalUrl = 'http://customer.quickse.in/'
export const customerPortalUrl = process.env.REACT_APP_CUSTOMER_URL
export const maxCountUpload = 1